import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IConfigs {
  welcomeVideoUrl: string;
}

export const getWelcomeVideoUrl = createAsyncThunk(
  "config/getWelcomeVideoUrl",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .post(`${apiUrls.config.findOneWithSpecificName}`, {name: "welcomeVideoUrl"})
      .then((res) => {
        response = res.data.value;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

const initialState: IConfigs = {
  welcomeVideoUrl: "",
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    clearAllConfigs: (state: IConfigs) => {
      state.welcomeVideoUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWelcomeVideoUrl.fulfilled, (state: IConfigs, { payload }: any) => {
      state.welcomeVideoUrl = payload;
    });
  },
});

export const configReducer = configSlice.reducer;

export const getCurrentWelcomeVideoUrl = (state: RootState) => state.config.welcomeVideoUrl;

export const { clearAllConfigs } = configSlice.actions;
