import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import { useTranslation } from "react-i18next";

export interface IProfilings {
  myActiveProfilings?: any[];
  quetion?: any;
  track?: any;
  type?: string;
}

export const getMyActiveProfilings = createAsyncThunk(
  "profilings/getMyActiveProfilings",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.profilings.getMyActive}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    console.log("profiling response: ", response);
    return response;
  },
);

export const createNewActiveProfilings = createAsyncThunk(
  "profilings/createNewActiveProfilings",
  async (data: any & IAppLoaderAction) => {
    let response;

    console.log("data answer", data);

    await axios.post<{ data: {answer: any} }>(apiUrls.profilings.createNewActive, {
        answer: data.answer
      })
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    console.log("profiling response: ", response);
    return response;
  },
);

export const whatsNextForCoaching = createAsyncThunk(
  "profilings/whatsNextForCoaching",
  async (componentId: IAppLoaderAction) => {
    let response: {type: string, data: any} = {type: "", data: {}};
    await axios
      .post(`${apiUrls.profilings.whatsNextForCoaching}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    console.log("whatsNextForCoaching response: ", response);
    return response;
  },
);

export const updateStatusToReviewForSpecificProfiling = createAsyncThunk(
  "profilings/updateStatusToReviewForSpecificProfiling",
  async ({ idOfProfiling }: { idOfProfiling: string } & IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.profilings.updateMy}/${idOfProfiling}`)
      .then((res) => {
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

const initialState: IProfilings = {
  myActiveProfilings: [],
  quetion: {},
  track: {},
  type: "",
};

export const profilingsSlice = createSlice({
  name: "profilings",
  initialState,
  reducers: {
    clearAllProfilings: (state: IProfilings) => {
      state.myActiveProfilings = [];
    },
    updatefullListOfMyProfilings: (state: IProfilings, { payload }) => {
      if (state.myActiveProfilings?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.myActiveProfilings[
          state.myActiveProfilings?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyActiveProfilings.fulfilled, (state: IProfilings, { payload }) => {
      state.myActiveProfilings = payload;
    });
    builder.addCase(createNewActiveProfilings.fulfilled, (state: IProfilings, { payload }) => {
      state.myActiveProfilings = payload;
    });
    builder.addCase(whatsNextForCoaching.fulfilled, (state: IProfilings, { payload }) => {
      if(payload?.type === "question")
      {
        state.quetion = payload?.data;
      }
      else if (payload?.type === "track")
      {
        state.track = payload?.data;
      }
      state.type = payload?.type;
    });
  },
});

export const profilingsReducer = profilingsSlice.reducer;

export const getMyCurrentActiveProfilings = (state: RootState) =>
  state.profilings.myActiveProfilings;

export const getCurrentQuestionToAsk = (state: RootState) =>
  state.profilings.quetion;  

export const getCurrentTrackToDo = (state: RootState) =>
  state.profilings.track;  

export const getCurrentType = (state: RootState) =>
  state.profilings.type;  

export const { clearAllProfilings } = profilingsSlice.actions;
