import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../../model/model";
import axios from "axios";
import apiUrls from "../../../api";
import {
  getAuthFromLocalStorage,
  storeAuthInLocalStorage,
} from "../../../common/util/localStorage";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { isTokenExpired } from "../../../common/util/auth";

export interface IAuthFormData {
  email: string;
  password: string;
  rememberme: boolean;
}

export interface IRegisterFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  termsAndConditions: boolean;
}

export interface IForgottenPasswordFormData {
  email: string;
}

export interface IResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

export interface IAuth {
  jwt: string;
  user: any;
  userId?: number;
}

export const signIn = createAsyncThunk(
  "auth/login",
  async ({ email, password }: IAuthFormData & IAppLoaderAction) => {
    let response: any;

    console.log("apiUrls.auth.signIn", apiUrls.auth.signIn);

    await axios
      .post<{ jwt: string; user: IUser }>(apiUrls.auth.signIn, {
        identifier: email,
        password,
      })
      .then((res) => {
        console.log("apiUrls.auth.signIn", apiUrls.auth.signIn);
        console.log("res", res);
        response = res.data;
      });

    const { jwt, user } = response;
    // Storing jwt and user in local storage
    storeAuthInLocalStorage(user, jwt, true);

    return { jwt, user };
  },
);

export const register = createAsyncThunk(
  "auth/register",
  async ({ firstname, lastname, email, password }: IRegisterFormData & IAppLoaderAction) => {
    let response: any;

    console.log("apiUrls.auth.register", apiUrls.auth.register);

    try {
      await axios
        .post(apiUrls.auth.register, {
          username: email,
          email: email,
          password: password,
          firstname: firstname,
          lastname: lastname,
        })
        .then(async (res) => {
          console.log("apiUrls.auth.register", apiUrls.auth.register);
          console.log("res", res);
          response = res.data;

          // // Send email confirmation request after successful registration
          // await axios.post(apiUrls.auth.sendEmailConfirmation, {
          //   email: email,
          // });
        });
    } catch (e: any) {
      console.error("error", e);
    }
    return;
  },
);

export const setCurrentUserAndJwt = createAsyncThunk(
  "profile/setCurrentUserAndJwt",
  // eslint-disable-next-line no-empty-pattern
  async () => {
    let jwt: string | undefined;
    let user: any | undefined;
    const authFromLocalStorage = getAuthFromLocalStorage();
    if (
      authFromLocalStorage.user &&
      authFromLocalStorage.jwt &&
      authFromLocalStorage.isRemebered &&
      authFromLocalStorage.jwt !== null &&
      !isTokenExpired(authFromLocalStorage.jwt)
    ) {
      console.log("2");
      jwt = authFromLocalStorage.jwt;
      user = authFromLocalStorage.user;
    } else {
      // await axios
      //   .get(`${apiUrls.register.users}/me`)
      //   .then((res) => {
      //     currentUser = res.data;
      //     organization = res.data.organization;
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //     return e;
      //   });
      console.log("3");
      jwt = "";
      user = undefined;
    }

    return { user, jwt };
  },
);

export const forgottenPassword = createAsyncThunk(
  "auth/forgottenPassword",
  async ({ email }: IForgottenPasswordFormData & IAppLoaderAction, { rejectWithValue }) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(apiUrls.auth.forgetPassword, {
        email,
      });
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    {
      password,
      passwordConfirmation,
      code,
    }: IResetPasswordFormData & { code: string } & IAppLoaderAction,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post<{ jwt: string; user: IUser }>(apiUrls.auth.resetPassword, {
        code: code,
        password: password,
        passwordConfirmation: passwordConfirmation,
      });
      return { response };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data);
      } else {
        throw err;
      }
    }
  },
);

const initialState: IAuth = { jwt: "", user: undefined };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state: IAuth, action: PayloadAction<{ jwt: string; user: any }>) => {
      state.jwt = action.payload.jwt;
      state.user = action.payload.user;
    },
    logoutAuth: (state: IAuth) => {
      state.jwt = "";
      state.user = undefined;
      delete axios.defaults.headers.common["Authorization"];
      window.localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.jwt = payload.jwt;
      state.user = payload.user;
    });
    builder.addCase(setCurrentUserAndJwt.fulfilled, (state, { payload }) => {
      state.jwt = payload.jwt;
      state.user = payload.user;
    });
  },
});

export const authReducer = authSlice.reducer;

export const getCurrentJwt = (state: IAuth) => state.jwt;
export const getCurrentUser = (state: IAuth) => state.user;

export const { logoutAuth, setAuth } = authSlice.actions;
