import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export const Success = () => {
  const [showElement, setShowElement] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 1000);
  }, []);

  return (
    <>
      {showElement && (
        <div
          className={
            "absolute left-1/2 top-1/2 transition-all duration-500 delay-200:hidden ease-out"
          }
        >
          <CheckCircleIcon className="text-center stroke-green-500" />
          <p className="text-green-500 text-center">Success</p>
        </div>
      )}
    </>
  );
};
