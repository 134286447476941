import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import {
  getCurrentInfoAboutMyUser,
} from "../../features/my-user/state/myUserSlice";
import {
  CustomInputStyle,
  ButtonIcon,
} from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const PROJECTS_COMPONENT_ID = "projectsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInUserComponent";
export const PROJECT_UPDATE_COMPONENT_ID = "UpdateProjectsComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInUserComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromTheUserComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromTheUserComponent";
export const USER_UPDATE_COMPONENT_ID = "userUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

const MyGroups = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { myProfile } = useSelector((state: RootState) => {
    return {
      myProfile: getCurrentInfoAboutMyUser(state),
    };
  });

  useEffect(() => {
    dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="absolute z-50 top-10 right-10">
        <ButtonIcon
          icon={"ArrowPathIcon"}
          classNameBtn={"w-14 h-14 bg-white rounded-full shadow-lg z-10 border"}
          classNameIcon={"w-10 h-10"}
          onClick={() => {
            dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));
          }}
        />
      </div>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={PROJECTS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
              <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
                <div className="flex justify-center items-center w-full h-full pb-6 pt-6 px-6">
                  <div className="w-full max-w-3xl h-auto pb-6 mx-auto pt-6 px-6">
                    {myProfile?.groups && myProfile?.groups?.length !== 0 ? (
                      <div className="h-auto p-5 min-h-[200px] w-full bg-white rounded-xl shadow-lg z-10">
                        <div className="flex flex-col w-full h-full max-w-9xl">
                          <div className="w-full flex flex-row mb-2">
                            <h2 className="pl-2 py-2 text-xl font-bold">
                              {t("pages.profile.groups.title")}
                            </h2>
                          </div>
                          <div className="grid grid-cols-1">
                            {myProfile.groups.map((group: any) => (
                              <CustomInputStyle title={group.name}>
                                <button
                                  className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                  onClick={() =>
                                    openInNewTab(
                                      `https://docs.google.com/document/d/${group.googledocouidshared}`,
                                    )
                                  }
                                >
                                  {String(t("pages.profile.groups.openMeetingsNotes"))}
                                </button>
                              </CustomInputStyle>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <IsLoading
                        componentId={UPDATE_GROUPS_IN_USER_COMPONENT_ID}
                        showSuccess={false}
                      >
                        <div className="xl:order-6 p-5 h-[200px] w-full bg-white rounded-xl shadow-lg z-10">
                          <div className="flex flex-col w-full h-full max-w-9xl">
                            <h2 className="pb-2 text-xl font-bold">{t("pages.profile.groups.title")}</h2>
                            <div className="flex-1 flex items-center justify-center">
                              <div>{t("pages.profile.emptyGroupsTable")}</div>
                            </div>
                          </div>
                        </div>
                      </IsLoading>
                    )}
                  </div>
                </div>
              </IsLoading>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default MyGroups;
