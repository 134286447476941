import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputIcon } from "ui";
import { useForm, useFormState } from "react-hook-form";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { IAuthFormData, signIn } from "../state/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import React from "react";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Error } from "../../../common/localComponents/AppState/Error";
import logo from "../../../assets/logo.png";
import bgImage from "../../../assets/bgMyCtoFriend.jpeg";
export const LOGIN_COMPONENT_ID = "loginComponent";

interface ILoginForm {
  labels: {
    title: string;
    subtitle: string;
    email: string;
    password: string;
    lostpwd: string;
    register: string;
    dontHaveAccount: string;
    login: string;
  };
  error: string;
  onSubmit: (data: any) => void;
}

export const LoginFormContainer = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { error, jwt } = useSelector((state: RootState) => {
    return {
      error: errorSelector(state, LOGIN_COMPONENT_ID),
      jwt: jwtSelector(state),
    };
  });

  const labels = {
    title: t("pages.login.title"),
    subtitle: t("pages.login.subtitle"),
    email: t("pages.login.email"),
    password: t("pages.login.password"),
    lostpwd: t("pages.login.lostpwd"),
    register: t("pages.login.register"),
    dontHaveAccount: t("pages.login.dontHaveAccount"),
    login: t("pages.login.login"),
  };

  const onSubmit = (data: IAuthFormData) => {
    dispatch(signIn({ componentId: LOGIN_COMPONENT_ID, ...data }));
  };

  React.useEffect(() => {
    if (jwt && jwt !== "") {
      navigate("/");
    }
  }, [jwt]);

  return <LoginForm labels={labels} onSubmit={onSubmit} error={error} />;
};

const LoginForm = ({ labels, onSubmit }: ILoginForm) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { register, handleSubmit, control } = useForm<IAuthFormData>();
  const { errors } = useFormState({ control });

  return (
    <div
      className="flex h-screen bg-cover"
      style={{
        backgroundImage: `url('${bgImage}')`,
        boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
      }}
    >
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white rounded-xl shadow-xl border border-gray-200 py-3 px-4">
            <div className="my-2">
              <div className="w-64 m-auto mb-3">
                <img src={logo} alt="myctofriend-logo" />
              </div>
              <h2 className="text-md text-black mb-1 text-center font-normal">{labels.subtitle}</h2>
              <div className="flex justify-center">
                <div className="flex items-center">
                  <p className="text-black font-normal text-xs border-0 mr-1">
                    {labels.dontHaveAccount}
                  </p>
                  <button className="text-blue hower:text-blue-darker font-medium text-xs border-0">
                    <Link to="/registration">{labels.register}</Link>
                  </button>
                </div>
              </div>
            </div>
            <Error componentId={LOGIN_COMPONENT_ID} />
            <IsLoading componentId={LOGIN_COMPONENT_ID} showSuccess={false} />

            <Input
              registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
              type="email"
              label={labels.email}
              placeholder="example@myctofriend.co"
              className={`${errors.email ? "border-red-500" : ""}`}
            />

            <InputIcon
              registerFct={() => register("password", { required: true })}
              type={passwordVisible ? "text" : "password"}
              position="right"
              label={labels.password}
              placeholder={labels.password}
              handleClick={() => setPasswordVisible(!passwordVisible)}
              icon={passwordVisible === true ? "EyeIcon" : "EyeSlashIcon"}
              className={`${errors.password ? "border-red-500" : ""}`}
            />
            <div className="flex justify-between">
              <button className="text-blue hower:text-blue-darker font-medium text-xs border-0 mt-1">
                <Link to="/forgottenpassword">{labels.lostpwd}</Link>
              </button>
            </div>
            <div className="grid pt-3 justify-items-end">
              <button className="btn-secondary-fill text-black" type="submit">
                {labels.login}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
