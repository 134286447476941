import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Navigation } from "ui";

const ZoomMeetingsWrapper = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navigation />
      <Outlet />
    </>
  );
};

export default ZoomMeetingsWrapper;
