import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Navigation } from "ui";

const MyProfileWrapper = () => {
  const { t } = useTranslation();

  // const selectedProfileTititle = useSelector(getSelectedProjectGroup);

  const tabs = [
    { title: t("pages.profile.tabs.myInfo"), urlEndPoint: "/profile" },
    { title: t("pages.profile.tabs.myProject"), urlEndPoint: "myProject" },
    { title: t("pages.profile.tabs.myGroups"), urlEndPoint: "myGroups" },
  ];

  return (
    <>
      <Navigation title="My Profile: " tabs={tabs} />
      <Outlet />
    </>
  );
};

export default MyProfileWrapper;
