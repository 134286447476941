import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { Navigation } from "ui";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getMyCurrentSpecificPool,
  getTabs,
  setTabs,
  closeTab,
} from "../../features/pools/state/poolsSlice";
import { useEffect, useState } from "react";
import { set } from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";

const PoolsWrapper = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch: AppDispatch = useDispatch();

  const { specificPool, tabs } = useSelector((state: RootState) => {
    return {
      specificPool: getMyCurrentSpecificPool(state),
      tabs: getTabs(state),
    };
  });

  const initialTitle = "My Environment: ";

  const [title, setTitle] = useState(initialTitle);

  const handleCloseTab = (id: any) => {
    const currentTabs = [...tabs];
    const tabIndex = currentTabs.findIndex((tab: any) => tab.urlEndPoint === `/tracks/${id}`);

    // Dispatch the updated tabs first
    dispatch(closeTab({ urlEndPoint: `/tracks/${id}` }));

    // Then update the URL, ensuring the dispatch has been completed
    setTimeout(() => {
      const newUrl = tabIndex > 0 ? currentTabs[tabIndex - 1].urlEndPoint : "/tracks";
      if (location.pathname === `/tracks/${id}`) {
        navigate(newUrl);
      }
    }, 0);
  };

  useEffect(() => {
    if (id) {
      if (!tabs.find((tab) => tab.urlEndPoint === `/tracks/${id}`)) {
        setTitle(title);
        const newTab = {
          title: `loading...`,
          urlEndPoint: `/tracks/${id}`,
          id: id,
        };
        dispatch(setTabs([...tabs, newTab]));
      }
    } else {
      setTitle("My Environment: ");
    }
  }, [id, location]);

  useEffect(() => {
    if (location.pathname === "/coachme") {
      setTitle("My Environment: ");
    }
  }, [location]);

  // Update tab title when specificPool changes
  useEffect(() => {
    if (id && specificPool?.track?.name) {
      setTitle(specificPool?.track?.name);
      const updatedTabs = tabs.map((tab) => {
        if (tab.urlEndPoint === `/tracks/${specificPool.id}`) {
          return {
            ...tab,
            title: specificPool?.track?.name,
            urlEndPoint: `/tracks/${specificPool.id}`,
            id: specificPool.id,
          };
        }
        return tab;
      });
      dispatch(setTabs(updatedTabs));
    }
  }, [specificPool]);

  return (
    <>
      <Navigation title={title} tabs={tabs} handleCloseTab={handleCloseTab} />
      <Outlet />
    </>
  );
};

export default PoolsWrapper;
