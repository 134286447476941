import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { DropdownManyChoices, Spinner } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import {
  getMyCurrentFullListOfPools,
  getMyCurrentSpecificPool,
  getMySpecificPool,
  updateStatusToReviewForSpecificPool,
} from "../../features/pools/state/poolsSlice";
import { Input, Dropdown, CustomInputStyle, ButtonIcon, DataGridComponent } from "ui";
import { ErrorMessage } from "@hookform/error-message";
import ReactPlayer from "react-player";
import { PlayCircleIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { CSSTransition } from "react-transition-group";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const SPECIFIC_POOL_COMPONENT_ID = "specificPoolComponent";
export const POOL_UPDATE_COMPONENT_ID = "poolUpdateComponent";

type IPoolInfo = {
  track: string;
  lastname: string;
  poolname: string;
  email: string;
  status: any;
  projectsowner: any[];
  groups: any[];
  selectedProjects: any[];
  selectedGroups: any[];
  pools: any[];
  blocked: boolean;
  confirmed: boolean;
};

const SpecificPool = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch: AppDispatch = useDispatch();

  const { specificPool } = useSelector((state: RootState) => {
    return {
      specificPool: getMyCurrentSpecificPool(state),
    };
  });

  useEffect(() => {
    dispatch(
      getMySpecificPool({
        componentId: SPECIFIC_POOL_COMPONENT_ID,
        idOfPool: `${id}`,
      }),
    ).then((res: any) => {
      console.log(res.payload);
    });
  }, [id]);

  interface ExpandedMap {
    [stepName: string]: boolean;
  }

  interface PlayingMap {
    [stepName: string]: boolean;
  }

  const [expanded, setExpanded] = useState<ExpandedMap>({});
  const [playing, setPlaying] = useState<PlayingMap>({});

  const handleStepClick = (stepName: string) => {
    const isCurrentlyExpanded = expanded[stepName];
    setExpanded({ ...expanded, [stepName]: !isCurrentlyExpanded });
  };

  const isExpanded = (stepName: string) => !!expanded[stepName];
  const isPlaying = (stepName: string) => !!playing[stepName];

  function handleVideoReady(stepName: string) {
    if (!isExpanded(stepName)) {
      setPlaying({ ...playing, [stepName]: false });
    }
  }

  const handleVideoPlay = (stepName: string) => {
    setPlaying({ ...playing, [stepName]: true });
  };

  const handleVideoPause = (stepName: string) => {
    setPlaying({ ...playing, [stepName]: false });
  };

  return (
    <>
      <div className="absolute z-50 top-10 right-10">
        {specificPool?.status?.name !== "published" && (
          <div className="flex justify-end align-middle ml-auto my-auto mr-8">
            <p className=" font-bold text-black">{`${t("pages.pools.specific.status")}: ${
              specificPool?.status?.name === "to review"
                ? t("pages.pools.specific.statusToReview")
                : specificPool?.status?.name === "to correct"
                ? t("pages.pools.specific.statusToCorrect")
                : specificPool?.status?.name === "reviewed"
                ? t("pages.pools.specific.statusReviewed")
                : specificPool?.status?.name
            }`}</p>
          </div>
        )}
      </div>
      <div className="relative w-full h-full overflow-visible">
        <div
          className="w-full h-full bg-cover absolute top-0 left-0"
          style={{
            backgroundImage: `url('${bgImage}')`,
            boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
          }}
        >
          <IsLoading componentId={SPECIFIC_POOL_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={POOL_UPDATE_COMPONENT_ID} showSuccess={false}>
              <div className="px-6 py-6">
                <div className="space-y-4">
                  {specificPool?.steps?.map((step: any) => (
                    <div key={step.name} className="bg-white rounded-2xl overflow-hidden shadow-md">
                      <div
                        className="flex items-center justify-between cursor-pointer py-4 px-6"
                        onClick={() => handleStepClick(step.name)}
                      >
                        <div className="w-full flex justify-between">
                          <div className="flex flex-col">
                            <div className="text-lg font-medium text-gray-900">{step.name}</div>
                            <div className="text-sm font-small text-gray-400">{`Step ${step.order.slice(
                              -1,
                            )}`}</div>
                          </div>
                          <div className="h-6 w-6 mr-2 flex my-auto justify-end">
                            {isExpanded(step.name) ? (
                              // <MinusIcon className="h-full w-full text-gray-500" />
                              <ChevronDownIcon className="text-center" />
                            ) : (
                              // <PlusIcon className="h-full w-full text-gray-500" />
                              <PlayCircleIcon className="text-center" />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* {isExpanded(step.name) && ( */}
                      <div
                        className={`flex justify-center items-center overflow-hidden transition-max-height duration-300  ${
                          isExpanded(step.name) ? "max-h-screen" : "max-h-0"
                        }`}
                      >
                        <div className="flex justify-center w-full h-full">
                          <div className="relative w-full mx-6 mb-6 aspect-video overflow-hidden rounded-xl max-w-6xl">
                            {/* {isLoading && <Spinner relative={false} style={{}} />} */}
                            {step.videourl && (
                              <ReactPlayer
                                className="w-full h-full"
                                width="100%"
                                height="100%"
                                style={{ maxWidth: "72rem" }}
                                controls
                                onReady={() => handleVideoReady(step.name) as any}
                                onPlay={() => handleVideoPlay(step.name)}
                                onPause={() => handleVideoPause(step.name)}
                                playing={isExpanded(step.name) && isPlaying(step.name)}
                                url={step.videourl}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="w-full">
                  <div className="flex flex-col justify-center  w-full">
                    <div className="mb-6 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                      <div className="flex justify-center w-full h-full">
                        <div className="relative w-full aspect-video overflow-hidden rounded-xl max-w-6xl">
                          {isLoading && <Spinner relative={false} style={{}} />}
                          {step.videourl && (
                              <ReactPlayer
                                className="absolute top-0 w-full h-auto"
                                width="100%"
                                height="100%"
                                style={{ maxWidth: "72rem" }}
                                controls
                                onReady={() => handleVideoReady(step.name) as any}
                                onPlay={() => handleVideoPlay(step.name)}
                                onPause={() => handleVideoPause(step.name)}
                                playing={isExpanded(step.name) && isPlaying(step.name)}
                                url={step.videourl}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                      {/* )} */}
                    </div>
                  ))}
                  <div className="flex">
                    {specificPool?.status?.name !== "to review" &&
                      specificPool?.status?.name !== "reviewed" && (
                        <button
                          className="btn-secondary-fill text-black mr-4 shadow w-60"
                          onClick={() => {
                            dispatch(
                              updateStatusToReviewForSpecificPool({
                                componentId: SPECIFIC_POOL_COMPONENT_ID,
                                idOfPool: `${id}`,
                              }),
                            ).then((res: any) => {
                              console.log(res.payload);
                            });
                          }}
                        >
                          <div className="flex justify-center items-center text-xl w-full">
                            <CheckCircleIcon className="text-center w-7 mr-2" />
                            <div className="inline-flex items-center text-xl">
                              {t("pages.pools.specific.validateThisTrack")}
                            </div>
                          </div>
                        </button>
                      )}
                    {specificPool?.status?.name === "to review" && (
                      <button className="btn-disabled text-black mr-4 shadow w-60" disabled>
                        <div className="flex justify-center items-center text-xl w-full">
                          <CheckCircleIcon className="text-center w-7 mr-2" />
                          <div className="inline-flex items-center text-xl">
                            {t("pages.pools.specific.statusToReview")}
                          </div>
                        </div>
                      </button>
                    )}
                    {specificPool?.status?.name === "reviewed" && (
                      <button className="btn-success text-black mr-4 shadow w-60" disabled>
                        <div className="flex justify-center items-center text-xl w-full">
                          <CheckCircleIcon className="text-center w-7 mr-2" />
                          <div className="inline-flex items-center text-xl">
                            {t("pages.pools.specific.statusReviewed")}
                          </div>
                        </div>
                      </button>
                    )}
                    <button
                      className="btn-secondary-fill text-black mr-4 shadow w-60"
                      onClick={() => {
                        window.open(
                          `https://docs.google.com/document/d/${specificPool.googledocouid}`,
                        );
                      }}
                    >
                      <div className="flex justify-center items-center text-xl w-full">
                        <DocumentTextIcon className="text-center w-7 mr-2" />
                        <div className="inline-flex items-center text-xl">
                          {t("pages.pools.specific.openWorkbook")}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </IsLoading>
          </IsLoading>
        </div>
      </div>
    </>
  );
};

export default SpecificPool;
