import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { DropdownManyChoices } from "ui";
import ModalConfirmation from "../../common/localComponents/Modal/ModalConfirmation";
import { closeStandardModal, openStandardModal } from "../../common/state/slice/modal/modalSlice";
import { AppDispatch, RootState } from "../../common/state/store";

import {
  getCurrentFullListOfGroups,
  getFullListOfGroups,
} from "../../features/groups/state/groupsSlice";
import {
  updateGroupsInTheMeeting,
  getCurrentGroupsThatAreNotInTheMeeting,
  getCurrentSpecificZoomMeeting,
  getGroupsThatAreNotInTheMeeting,
  getSpecificZoomMeeting,
  updateSpecificZoomMeeting,
} from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { groupsColumns } from "../Profile/groups-columns";
import ReactPlayer from "react-player";
import moment from "moment";
import { ButtonIcon, Input, DataGridComponent, CustomInputStyle, Spinner } from "ui";
import { getCurrentInfoAboutMyUser, getMyUser } from "../../features/my-user/state/myUserSlice";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const ZOOMMEETINGS_UPDATE_COMPONENT_ID = "ZoomMeetingsUpdateComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const MY_USER_COMPONENT_ID = "MyUserComponent";
export const GROUPS_NOT_IN_MEETING_COMPONENT_ID = "groupsNotInMeetingComponent";
export const UPDATE_ZOOMMEETING_COMPONENT_ID = "UpdateZoomMeetingsComponent";
export const REMOVE_THIS_GROUP_FROM_MEETINGS_COMPONENT_ID =
  "removeThisGroupFromTheMeetingComponent";

interface IGroupsInfo {
  groups: string;
}

type IZoomMeetingsInfo = {
  topic: string;
  start_time: string;
};

const SpecificZoomMeeting = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [idOfChosenGroups, setIdOfChosenGroups] = useState<number>();
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    users: false,
  });

  const { fullListOfGroups, specificZoomMeeting, groupsThatAreNotInTheMeeting, myUser } =
    useSelector((state: RootState) => {
      return {
        fullListOfGroups: getCurrentFullListOfGroups(state),
        specificZoomMeeting: getCurrentSpecificZoomMeeting(state),
        groupsThatAreNotInTheMeeting: getCurrentGroupsThatAreNotInTheMeeting(state),
        myUser: getCurrentInfoAboutMyUser(state),
      };
    });

  // const fullListOfGroups  = getFullListOfGroups({ componentId:GROUPS_COMPONENT_ID });
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const temp = location.pathname.split("/");
  const idOfMeeting = temp[2];

  useEffect(() => {
    dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));

    dispatch(
      getSpecificZoomMeeting({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
        idOfMeeting: idOfMeeting,
      }),
    ).then((result: any) => {
      console.log("result", result);
      console.log("result.payload", result.payload);
      meetingsReset({
        topic: result?.payload.topic,
        start_time: moment(result?.payload.start_time).utc().format("HH:mm DD-MM-YYYY"),
      });

      let forNullUrl = null;

      for (let i = 0; i < result?.payload?.zoomrecordings?.length; i++) {
        if (result.payload.zoomrecordings[i].recording_type === "shared_screen_with_speaker_view") {
          forNullUrl = result.payload.zoomrecordings[i].recordingurl;
          console.log("forNullUrl", forNullUrl);
          setUrl(result.payload.zoomrecordings[i].recordingurl);
          console.log("url", url);
          console.log(
            "result.payload.zoomrecordings[i].recordingurl",
            result.payload.zoomrecordings[i].recordingurl,
          );
        }
      }

      if (forNullUrl === null) {
        for (let i = 0; i < result.payload.zoomrecordings.length; i++) {
          if (result.payload.zoomrecordings[i].recording_type === "active_speaker") {
            forNullUrl = result.payload.zoomrecordings[i].recordingurl;
            console.log("forNullUrl", forNullUrl);
            setUrl(result.payload.zoomrecordings[i].recordingurl);
            console.log("url", url);
            console.log(
              "result.payload.zoomrecordings[i].recordingurl",
              result.payload.zoomrecordings[i].recordingurl,
            );
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getMyUser({
        componentId: MY_USER_COMPONENT_ID,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getGroupsThatAreNotInTheMeeting({
        componentId: GROUPS_NOT_IN_MEETING_COMPONENT_ID,
        fullListOfGroups: fullListOfGroups as any,
        specificZoomMeeting: specificZoomMeeting,
      }),
    );
  }, [fullListOfGroups, specificZoomMeeting]);

  const {
    register: meetingsRegister,
    control: meetingsControl,
    handleSubmit: meetingsHandleSubmit,
    reset: meetingsReset,
    watch: meetingsWatch,
    formState: { errors: meetingsErrors },
  } = useForm<IZoomMeetingsInfo>({
    defaultValues: {
      topic: specificZoomMeeting?.topic,
      start_time: moment(specificZoomMeeting?.start_time).utc().format("HH:mm DD-MM-YYYY"),
    },
  });

  const {
    control: groupsControl,
    handleSubmit: groupsHandleSubmit,
    watch: groupsWatch,
    formState: { errors: groupsErrors },
  } = useForm<IGroupsInfo>();

  const meetingsCancel = () => {
    meetingsReset({
      topic: specificZoomMeeting?.topic,
      start_time: moment(specificZoomMeeting?.start_time).utc().format("HH:mm DD-MM-YYYY"),
    });

    setNotEditable(true);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        //if in params, array of users object contains current user id then show button
        if (params.row.users.some((user: any) => user.id === myUser.id)) {
          return (
            <button
              type="button"
              className="btn-primary-fill bg-blue mr-2"
              onClick={() => {
                window.open(`https://docs.google.com/document/d/${params.googledocouidshared}`);
              }}
            >
              {String(t("pages.profile.openGroupsNotes"))}
            </button>
          );
        }
      },
    },
  ];

  const [pageSize, setPageSize] = useState(10);
  const [notEditable, setNotEditable] = useState(true);

  const onSubmitZoomMeetingsInfo = (data: any) => {
    const updatedZoomMeeting = {
      topic: data.topic,
    };

    dispatch(
      updateSpecificZoomMeeting({
        componentId: ZOOMMEETINGS_UPDATE_COMPONENT_ID,
        idOfMeeting: specificZoomMeeting.id,
        data: updatedZoomMeeting,
      }),
    )
      .then(() => {
        setNotEditable(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const handleDelete = (id: number) => {
    const temp = specificZoomMeeting.groups.filter((item: any) => item.id !== id);

    const arrayOfGroupsId = [];

    for (const [key, value] of Object.entries(temp) as any) {
      arrayOfGroupsId.push(value.id);
    }

    dispatch(
      updateGroupsInTheMeeting({
        componentId: REMOVE_THIS_GROUP_FROM_MEETINGS_COMPONENT_ID,
        idOfMeeting: idOfMeeting,
        arrayOfGroupsId: arrayOfGroupsId,
      }),
    ).then(() => {
      dispatch(closeStandardModal("removeThisGroupFromTheMeeting"));
    });
  };

  const selectedGroups: any = groupsWatch().groups;

  return (
    <>
      <ModalConfirmation
        id="removeThisGroupFromTheMeeting"
        onClick={() => {
          if (idOfChosenGroups !== undefined) {
            handleDelete(idOfChosenGroups);
          }
        }}
        title={t("pages.zoomMeetings.topicOfConfirmationPopup")}
        text={t("pages.zoomMeetings.textOfConfirmationPopup")}
        componentId={REMOVE_THIS_GROUP_FROM_MEETINGS_COMPONENT_ID}
      />
      <header className="flex w-full bg-white shadow  sticky top-12 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.zoomMeetings.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfGroups({ componentId: GROUPS_COMPONENT_ID }));

              dispatch(
                getSpecificZoomMeeting({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                  idOfMeeting: idOfMeeting,
                }),
              ).then((result: any) => {
                let forNullUrl = null;

                for (let i = 0; i < result.payload.zoomrecordings.length; i++) {
                  if (
                    result.payload.zoomrecordings[i].recording_type ===
                    "shared_screen_with_speaker_view"
                  ) {
                    forNullUrl = result.payload.zoomrecordings[i].recordingurl;
                    setUrl(result.payload.zoomrecordings[i].recordingurl);
                  }
                }

                if (forNullUrl === null) {
                  for (let i = 0; i < result.payload.zoomrecordings.length; i++) {
                    if (result.payload.zoomrecordings[i].recording_type === "active_speaker") {
                      forNullUrl = result.payload.zoomrecordings[i].recordingurl;
                      setUrl(result.payload.zoomrecordings[i].recordingurl);
                    }
                  }
                }
              });
            }}
          />
        </div>
      </header>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={GROUPS_NOT_IN_MEETING_COMPONENT_ID} showSuccess={false}>
              <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:pb-4">
                  <form
                    onSubmit={meetingsHandleSubmit(onSubmitZoomMeetingsInfo)}
                    className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md"
                  >
                    <div className="flex flex-col w-full h-full max-w-9xl">
                      <div className="relative grow w-full">
                        <div className="border-gray-300">
                          <div className="w-full flex flex-row mb-2">
                            <h2 className="pl-2 py-2 text-xl font-bold">
                              {t("pages.zoomMeetings.info")}
                            </h2>
                            <div className="flex grow justify-end h-[38px] my-auto">
                              {notEditable && (
                                <button
                                  className="btn-primary-fill"
                                  type="button"
                                  onClick={() => {
                                    setNotEditable(false);
                                  }}
                                >
                                  {t("general.edit")}
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="grid grid-cols-1 xl:gap-4">
                            <div className="relative flex flex-col w-full h-full max-w-9xl">
                              <div className="w-full h-full max-w-9xl">
                                <div className="relative grow w-full">
                                  <div className="border-gray-300">
                                    <div className="h-full">
                                      <CustomInputStyle title={t("pages.zoomMeetings.topic")}>
                                        <Input
                                          type="text"
                                          readOnly={notEditable}
                                          registerFct={() => meetingsRegister("topic")}
                                          className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                          placeholder={t("pages.zoomMeetings.topicPlaceholder")}
                                        />
                                      </CustomInputStyle>
                                      <CustomInputStyle title={t("pages.zoomMeetings.startTime")}>
                                        <Input
                                          type="text"
                                          readOnly={true}
                                          registerFct={() => meetingsRegister("start_time")}
                                          className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600  max-w-[250px] text-center sm:text-left ${ ? }"
                                          placeholder={t("pages.zoomMeetings.startTimePlaceholder")}
                                        />
                                      </CustomInputStyle>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end align-bottom h-[38px]">
                        <IsLoading
                          componentId={ZOOMMEETINGS_UPDATE_COMPONENT_ID}
                          showSuccess={false}
                        >
                          <>
                            {notEditable === false && (
                              <>
                                <button
                                  type="button"
                                  className="btn-primary-outline mr-4"
                                  onClick={() => meetingsCancel()}
                                >
                                  {t("general.cancel")}
                                </button>
                                <button className="btn-primary-fill" type="submit">
                                  {t("general.save")}
                                </button>
                              </>
                            )}
                          </>
                        </IsLoading>
                      </div>
                    </div>
                  </form>

                  <div className="p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                    {specificZoomMeeting?.groups && specificZoomMeeting?.groups?.length !== 0 ? (
                      <div className="relative">
                        <h2 className="pl-2 py-2 mb-2 text-xl font-bold">
                          {t("pages.zoomMeetings.groupsInfo")}
                        </h2>
                        <div className="w-full h-full max-w-9xl">
                          <DataGridComponent
                            autoHeight={true}
                            className="bg-white rounded-xl"
                            rows={specificZoomMeeting.groups as GridRowsProp}
                            columns={groupsColumns.concat(actionColumn as any) as GridColDef[]}
                            rowsPerPageOptions={[5, 10, 20]}
                            columnVisibilityModel={columnVisibilityModel}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex w-auto">
                        <div className="relative pt-6 pb-16 mr-auto ml-auto">
                          <IsLoading
                            componentId={UPDATE_ZOOMMEETING_COMPONENT_ID}
                            showSuccess={false}
                          >
                            {t("pages.zoomMeetings.emptyGroupsTable")}
                          </IsLoading>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex flex-col justify-center  w-full">
                    <div className="mb-6 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-md">
                      <div className="flex justify-center w-full h-full">
                        <div className="relative w-full aspect-video overflow-hidden rounded-xl max-w-6xl">
                          {isLoading && <Spinner relative={false} style={{}} />}
                          {url && (
                            <ReactPlayer
                              className="w-full h-full"
                              width="100%"
                              height="100%"
                              style={{}}
                              controls
                              onReady={() => setIsLoading(false)}
                              url={url}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default SpecificZoomMeeting;
