import { getGridStringOperators } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

export const groupsColumns = [
  {
    field: "name",
    headerName: "Group's Name",
    minWidth: 150,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };
      return (
        <Link to={`/groups/${params.id}`}>
          <p>{params.row.name}</p>
        </Link>
      );
    },
    valueGetter: (params: any) => {
      return params.row.name;
    },
  },
  {
    field: "users",
    headerName: "Users",
    type: "string",
    minWidth: 150,
    flex: 1,
    renderCell: (params: any) => {
      const onClickEdit = (e: any) => {
        e.stopPropagation(); // don't select this row after clicking
      };

      if (!params.row.users || params.row.users === null || params.row.users.length === 0) {
        return null;
      } else if (params.row.users.length === 1) {
        return params.row.users[0]?.email;
      } else {
        const arr = Object.values(params.row.users);

        const numOfUsers = arr.length;

        const arrNew = [];
        for (let i = 0; i < numOfUsers; i++) {
          arrNew.push(params.row.users[i]?.email);
        }

        return (
          <div>
            <select
              style={{ width: `${70}px` }}
              defaultValue={numOfUsers}
              placeholder={String(numOfUsers)}
              onClick={onClickEdit}
            >
              <option disabled hidden>
                {numOfUsers}
              </option>
              {arrNew.map((value, index) => (
                <option disabled key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        );
      }
    },
    valueGetter: (params: any) => {
      if (!params.row.users || params.row.users === null || params.row.users.length === 0) {
        return null;
      } else if (params.row.users.length === 1) {
        return params.row.users[0]?.email;
      } else {
        const arr = Object.values(params.row.users);

        const numOfUsers = arr.length;

        let strNew = "";
        for (let i = 0; i < numOfUsers; i++) {
          strNew += ` ${params.row.users[i]?.email}`;
        }

        return strNew;
      }
    },
    sortComparator: (v1: any, v2: any, sort: any) => {
      if (v1 === null && v2 === null) return 0;
      else if (v1 === null) return 1;
      else if (v2 === null) return -1;
      else return `${v1}`.localeCompare(`${v2}`);
    },
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "isEmpty" ||
        operator.value === "isNotEmpty",
    ),
  },
];
