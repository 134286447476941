import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { poolsColumns } from "./pools-columns";
import { Link } from "react-router-dom";
import {
  getMyCurrentFullListOfPools,
  getFullListOfMyPools,
} from "../../features/pools/state/poolsSlice";
import { ButtonIcon, DataGridComponent } from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export const USERS_COMPONENT_ID = "poolsComponent";

const Pools = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { fullListOfPools } = useSelector((state: RootState) => {
    return {
      fullListOfPools: getMyCurrentFullListOfPools(state),
    };
  });

  useEffect(() => {
    if (!fullListOfPools || fullListOfPools.length === 0) {
      dispatch(getFullListOfMyPools({ componentId: USERS_COMPONENT_ID }));
    }
  }, []);

  return (
    <>
      <div className="absolute z-50 top-10 right-10">
        <ButtonIcon
          icon={"ArrowPathIcon"}
          classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
          classNameIcon={"w-10 h-10"}
          onClick={() => {
            dispatch(getFullListOfMyPools({ componentId: USERS_COMPONENT_ID }));
          }}
        />
      </div>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
          <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
            <div className="space-y-4">
              {fullListOfPools && fullListOfPools && fullListOfPools.length !== 0 ? (
                fullListOfPools
                  .filter((pool: any) => pool?.track)
                  .map((pool: any) => (
                    <div key={pool.id} className="bg-white rounded-2xl overflow-hidden shadow-md">
                      <div
                        className="flex items-center justify-between cursor-pointer py-4 px-6"
                        onClick={() => {
                          //open new page tracks/${pool.id}
                          navigate(`/tracks/${pool.id}`);
                        }}
                      >
                        <div className="w-full flex justify-between">
                          <div className="flex flex-col">
                            <div className="text-lg font-medium text-gray-900">
                              {pool?.track?.name}
                            </div>
                            <div className="text-sm font-small text-gray-400">{`Status: ${
                              pool?.status?.name ?? ""
                            }`}</div>
                          </div>
                          <div className="h-6 w-6 mr-2 flex my-auto justify-end">
                            <CursorArrowRaysIcon className="text-center" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="bg-white rounded-2xl overflow-hidden shadow-md">
                  <div className="flex items-center justify-between cursor-pointer py-4 px-6">
                    <div className="w-full flex justify-between">
                      <div className="flex flex-col">
                        <div className="text-lg font-medium text-gray-900">
                          You have no tracks, yet.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </IsLoading>
        </div>
      </div>
    </>
  );
};

export default Pools;
