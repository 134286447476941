import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { stat } from "fs";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";
import { myUserSlice } from "../../my-user/state/myUserSlice";
export interface IProjects {
  myProjects: any[];
  myFirstProject: any;
}

export const getMyProjects = createAsyncThunk(
  "projects/getMyProjects",
  async ({ data }: { idOfProject: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .post(`${apiUrls.projects.getMy}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const updateMyFirstProject = createAsyncThunk(
  "projects/updateMyFirstProject",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    //junky solution recive an array of projects and return the first one
    let response;
    console.log(data);
    await axios
      .post(`${apiUrls.projects.updateMy}`, { ...data })
      .then((res) => {
        console.log("data", data);
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    console.log("response", response);
    return response;
  },
);

export const createMyFirstProject = createAsyncThunk(
  "projects/createMyFirstProject",
  async ({ data }: { data: any } & IAppLoaderAction) => {
    //junky solution recive an array of projects and return the first one
    let response;
    console.log(data);
    await axios
      .post(`${apiUrls.projects.createMy}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

const initialState: IProjects = {
  myProjects: [],
  myFirstProject: {},
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clearMyProjects: (state: IProjects) => {
      state.myProjects = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProjects.fulfilled, (state: IProjects, { payload }: any) => {
      state.myProjects = payload;
      state.myFirstProject = payload[0];
    });
    builder.addCase(updateMyFirstProject.fulfilled, (state: IProjects, { payload }: any) => {
      state.myFirstProject = payload;
      state.myProjects[0] = payload;
      // myUserSlice.caseReducers.updateMyFirstProjectInTheUser(payload);
    });
    builder.addCase(createMyFirstProject.fulfilled, (state: IProjects, { payload }: any) => {
      state.myFirstProject = payload;
      state.myProjects[0] = payload;
      // myUserSlice.caseReducers.createMyFirstProjectInTheUser(payload);
    });
  },
});

export const projectsReducer = projectsSlice.reducer;

export const getCurrentProjects = (state: RootState) => state.projects.myProjects;

export const { clearMyProjects } = projectsSlice.actions;
