import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentInfoAboutMyUser,
  getMyUser,
} from "../../features/my-user/state/myUserSlice";
import {
  Input,
  CustomInputStyle,
  ButtonIcon,
  DynamicTextarea,
  CustomTextareaStyle,
} from "ui";
import {
  updateMyFirstProject,
  createMyFirstProject,
} from "../../features/projects/state/projectsSlice";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const PROJECTS_COMPONENT_ID = "projectsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInUserComponent";
export const PROJECT_UPDATE_COMPONENT_ID = "UpdateProjectsComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInUserComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromTheUserComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromTheUserComponent";
export const USER_UPDATE_COMPONENT_ID = "userUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type IProfileInfo = {
  firstname: string;
  lastname: string;
  email: string;
  groups: any[];
};

type IProjectInfo = {
  name: any;
  description: any;
};

const MyProject = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { myProfile } = useSelector((state: RootState) => {
    return {
      myProfile: getCurrentInfoAboutMyUser(state),
    };
  });

  //for projects
  const {
    register: registerProjects,
    control: controlProjects,
    handleSubmit: handleSubmitProjects,
    reset: resetProjects,
    watch: watchProjects,
    formState: { errors: errorsProjects },
  } = useForm<IProjectInfo>();

  const [notEditableProject, setNotEditableProject] = useState(true);

  type CreateProjectStatus = "pending" | "fulfilled" | null;

  const [showDescription, setShowDescription] = useState(false);
  const [createProjectStatus, setCreateProjectStatus] = useState<CreateProjectStatus>(null);

  const location = useLocation();

  useEffect(() => {
    dispatch(
      getMyUser({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      resetProjects({
        name: res.payload?.projectsowner[0]?.name,
        description: res.payload?.projectsowner[0]?.description,
      });
    });
  }, []);

  const onSubmitProjectInfo = (data: any) => {
    const updatedProject = {
      name: data.name,
      description: data.description,
    };

    dispatch(
      updateMyFirstProject({
        componentId: PROJECT_UPDATE_COMPONENT_ID,
        data: updatedProject,
      }),
    )
      .then(() => {
        setNotEditableProject(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const createProject = (data: any) => {
    const updatedProject = {
      name: data.name,
      // description: data.description,
    };

    setCreateProjectStatus(null);

    dispatch(
      createMyFirstProject({
        componentId: PROJECT_UPDATE_COMPONENT_ID,
        data: updatedProject,
      }),
    )
      .then(() => {
        setNotEditableProject(true);
        setCreateProjectStatus("fulfilled");
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });

    setCreateProjectStatus("pending");
    setShowDescription(true);
  };

  const updateProject = (data: any) => {
    const updatedProject = {
      description: data.description,
    };

    dispatch(
      updateMyFirstProject({
        componentId: PROJECT_UPDATE_COMPONENT_ID,
        data: updatedProject,
      }),
    )
      .then(() => {
        setNotEditableProject(true);

        setShowDescription(false);
        setCreateProjectStatus(null);

        dispatch(
          getMyUser({
            componentId: ZOOMMEETINGS_COMPONENT_ID,
          }),
        ).then((res: any) => {
          resetProjects({
            name: res.payload?.projectsowner[0]?.name,
            description: res.payload?.projectsowner[0]?.description,
          });
        });
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancelProject = () => {
    resetProjects({
      name: myProfile?.projectsowner[0]?.name,
      description: myProfile?.projectsowner[0]?.description,
    });

    setNotEditableProject(true);
  };

  return (
    <>
      <div className="absolute z-50 top-10 right-10">
        <ButtonIcon
          icon={"ArrowPathIcon"}
          classNameBtn={"w-14 h-14 bg-white rounded-full shadow-lg z-10 border"}
          classNameIcon={"w-10 h-10"}
          onClick={() => {
            dispatch(
              getMyUser({
                componentId: ZOOMMEETINGS_COMPONENT_ID,
              }),
            ).then((res: any) => {
              console.log(res.payload);
              resetProjects({
                name: res.payload?.projectsowner[0]?.name,
                description: res.payload?.projectsowner[0]?.description,
              });
            });
          }}
        />
      </div>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={PROJECTS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
              <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
                <div className="flex justify-center items-center w-full h-full pb-6 pt-6 px-6">
                  <div className="w-full max-w-3xl h-auto pb-6 mx-auto pt-6 px-6">
                    <div className="h-auto p-5 min-h-[200px] w-full bg-white rounded-xl shadow-lg z-10">
                      {myProfile?.projectsowner && myProfile?.projectsowner?.length !== 0 ? (
                        <form
                          onSubmit={handleSubmitProjects(onSubmitProjectInfo)}
                          className="flex-col h-full"
                        >
                          <div className="w-full max-w-9xl">
                            <div className="flex flex-row mb-2">
                              <h2 className="pl-2 py-2 text-xl font-bold">
                                {t("pages.profile.project.title")}
                              </h2>
                              <div className="flex grow justify-end h-[39px] my-auto">
                                {notEditableProject && (
                                  <button
                                    className="btn-primary-fill"
                                    type="button"
                                    onClick={() => {
                                      setNotEditableProject(false);
                                    }}
                                  >
                                    {t("general.edit")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-1">
                            <div>
                              <CustomInputStyle title={t("pages.profile.project.name")}>
                                <Input
                                  type="text"
                                  readOnly={notEditableProject}
                                  registerFct={() => registerProjects("name", { required: true })}
                                  className={`${
                                    errorsProjects.name ? "border-red-500" : ""
                                  } read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left`}
                                  placeholder={t("pages.profile.project.namePlaceholder")}
                                />
                              </CustomInputStyle>
                            </div>
                            <div>
                              <CustomTextareaStyle title={t("pages.profile.project.description")}>
                                <DynamicTextarea
                                  readOnly={notEditableProject}
                                  registerFct={() => registerProjects("description")}
                                  // style={{ resize: notEditable ? "none" : "vertical" }}
                                  defaultHeight={100}
                                  style={{
                                    resize: "none",
                                    height: notEditableProject ? "auto" : "",
                                  }}
                                  className=" min-h-[38px] read-only:cursor-default w-full font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                  placeholder={t("pages.profile.project.descriptionPlaceholder")}
                                />
                              </CustomTextareaStyle>
                            </div>
                          </div>
                          <div className="flex justify-end h-[39px] mt-1">
                            <IsLoading
                              componentId={PROJECT_UPDATE_COMPONENT_ID}
                              showSuccess={false}
                            >
                              <>
                                {notEditableProject === false && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn-primary-outline mr-4"
                                      onClick={() => cancelProject()}
                                    >
                                      {t("general.cancel")}
                                    </button>
                                    <button className="btn-primary-fill" type="submit">
                                      {t("general.save")}
                                    </button>
                                  </>
                                )}
                              </>
                            </IsLoading>
                          </div>
                        </form>
                      ) : !showDescription ? (
                        <form onSubmit={handleSubmitProjects(createProject)}>
                          <div className="flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full flex flex-row mb-2">
                              <h2 className="pl-2 py-2 text-xl font-bold">
                                {t("pages.profile.project.tellusName")}
                              </h2>
                            </div>
                          </div>
                          <div className="grid grid-cols-1">
                            <div>
                              <CustomInputStyle title={t("pages.profile.project.name")}>
                                <Input
                                  type="text"
                                  registerFct={() => registerProjects("name", { required: true })}
                                  className={`${
                                    errorsProjects.name ? "border-red-500" : ""
                                  } read-only:cursor-default font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left`}
                                  placeholder={t("pages.profile.project.namePlaceholder")}
                                />
                              </CustomInputStyle>
                            </div>
                          </div>
                          <div className="flex justify-end h-[39px] mt-1">
                            <IsLoading
                              componentId={PROJECT_UPDATE_COMPONENT_ID}
                              showSuccess={false}
                            >
                              <>
                                <button className="btn-primary-fill" type="submit">
                                  {t("general.save")}
                                </button>
                              </>
                            </IsLoading>
                          </div>
                        </form>
                      ) : (
                        <form onSubmit={handleSubmitProjects(updateProject)}>
                          <div className="flex flex-col w-full h-full max-w-9xl">
                            <div className="w-full flex flex-row mb-2">
                              <h2 className="pl-2 py-2 text-xl font-bold">
                                {t("pages.profile.project.tellusDescription")}
                              </h2>
                            </div>
                          </div>
                          <div className="grid grid-cols-1">
                            <div>
                              <CustomTextareaStyle title={t("pages.profile.project.description")}>
                                <DynamicTextarea
                                  registerFct={() => registerProjects("description")}
                                  style={{ resize: "none", height: "" }}
                                  defaultHeight={100}
                                  className="min-h-[38px] read-only:cursor-default w-full font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left"
                                  placeholder={t("pages.profile.project.descriptionPlaceholder")}
                                />
                              </CustomTextareaStyle>
                            </div>
                          </div>
                          <div className="flex justify-end h-[39px] mt-1">
                            <IsLoading
                              componentId={PROJECT_UPDATE_COMPONENT_ID}
                              showSuccess={false}
                              spinnerPlaceholder={true}
                            >
                              <>
                                <button className="btn-primary-fill" type="submit">
                                  {t("general.save")}
                                </button>
                              </>
                            </IsLoading>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </IsLoading>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default MyProject;
