import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import { SidebarItemSmall, SidebarItem } from "ui";
import { SidebarItemSmall } from "ui";
import { SidebarItem } from "ui";
import { IconName } from "ui/components/Icon/Icon";
import { logoutAuth } from "../../../features/auth/state/authSlice";
import logo from "../../../assets/logo.png";
import { clearAllZoomMeetings } from "../../../features/zoomMeetings/state/zoomMeetingsSlice";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type SideBarLinkProps = {
  title: string;
  iconName: IconName;
  routePath: string;
};

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const [showLogo, setShowLogo] = useState(false);

  const sidebarLinks: SideBarLinkProps[] = [
    { title: t("sidebar.dashboard"), iconName: "HomeIcon", routePath: "./" },
    { title: t("sidebar.profile"), iconName: "UserIcon", routePath: "/profile" },
    { title: t("sidebar.zoomMeetings"), iconName: "ChartBarIcon", routePath: "/meetings" },
    { title: t("sidebar.tracks"), iconName: "UserIcon", routePath: "/tracks" },
  ];

  useEffect(() => {
    setShowLogo(location.pathname !== "/");
  }, [location.pathname]);

  return (
    <div className="flex flex-col justify-between w-48 pt-3 pb-6 shadow bg-white z-50">
      <div>
        <div className="mb-10 px-1 h-20 mt-6">
          {/* <Transition
            show={showLogo}
            enter="transition ease-in-out duration-100 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-100 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          > */}
          <div className="flex justify-center ">
            <img className="object-scale-down w-36" src={logo} alt="myctofriend-logo" />
          </div>
          {/* </Transition> */}
        </div>

        <div>
          {sidebarLinks.map((link, i) => {
            return (
              <SidebarItem
                routeTo={link.routePath}
                key={i}
                title={link.title}
                icon={link.iconName}
              />
            );
          })}
        </div>
      </div>
      <div className=" flex flex-col justify-between divide-y">
        <div className="py-4">
          <SidebarItem
            icon="ArrowLeftOnRectangleIcon"
            title={t("sidebar.logout")}
            onClick={() => {
              dispatch(logoutAuth());
              dispatch(clearAllZoomMeetings());
            }}
            routeTo={"/login"}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
