import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IZoomMeetings {
  fullListOfMeetings: any[];
  smListOfMeetings: any[];
  specificZoomMeeting: any;
  groupsThatAreNotInTheMeeting: any[];
}

export const getSmListOfZoomMeetings = createAsyncThunk(
  "zoomMeetings/getSmListOfZoomMeetings",
  async (componentId: IAppLoaderAction) => {
    let response;

    await axios
      .post(`${apiUrls.zoomMeetings.getMy}?_sort=id:DESC&_limit=100`, {})
      .then((res) => {
        console.log("res.data.data", res.data.data);
        response = res.data.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const getFullListOfZoomMeetings = createAsyncThunk(
  "zoomMeetings/getFullListOfZoomMeetings",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.zoomMeetings.getMy}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        console.log("res.data", res.data);
        console.log("res.data.data", res.data.data);
        response = res.data.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const getSpecificZoomMeeting = createAsyncThunk(
  "zoomMeetings/getSpecificZoomMeeting",
  async ({ idOfMeeting }: { idOfMeeting: string } & IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.zoomMeetings.getMy}/${idOfMeeting}`)
      .then((res) => {
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateSpecificZoomMeeting = createAsyncThunk(
  "zoomMeetings/updateSpecificZoomMeeting",
  async ({ idOfMeeting, data }: { idOfMeeting: string; data: any } & IAppLoaderAction) => {
    let response;
    console.log(data);
    await axios
      .post(`${apiUrls.zoomMeetings.updateMy}/${idOfMeeting}`, { ...data })
      .then((res) => {
        console.log(data);
        console.log("res.data", res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const updateGroupsInTheMeeting = createAsyncThunk(
  "zoomMeetings/updateGroupsInTheMeeting",
  async ({
    idOfMeeting,
    arrayOfGroupsId,
  }: { idOfMeeting: string; arrayOfGroupsId: any } & IAppLoaderAction) => {
    let response;

    console.log("idOfMeeting", idOfMeeting);

    console.log("arrayOfGroupsId", arrayOfGroupsId);

    await axios
      .put(`${apiUrls.zoomMeetings.getMy}/${idOfMeeting}`, { groups: arrayOfGroupsId })
      .then((res) => {
        console.log("res.data", res.data);

        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

export const getGroupsThatAreNotInTheMeeting = createAsyncThunk(
  "zoomMeetings/getGroupsThatAreNotInTheMeeting",
  async ({
    specificZoomMeeting,
    fullListOfGroups,
  }: { specificZoomMeeting: any; fullListOfGroups: any[] } & IAppLoaderAction) => {
    if (specificZoomMeeting?.groups?.length !== 0) {
      const arrOfExistedGroups = [];

      for (let i = 0; i < specificZoomMeeting.groups.length; i++) {
        arrOfExistedGroups.push(specificZoomMeeting.groups[i].id);
      }

      let objWithGroupsNotExisted: any = fullListOfGroups;

      arrOfExistedGroups.forEach((id) => {
        if (objWithGroupsNotExisted != undefined) {
          objWithGroupsNotExisted = objWithGroupsNotExisted.filter(
            (element: any) => element.id !== id,
          );
        }
      });

      const optionsNeeded = objWithGroupsNotExisted.map((x: any) => ({
        value: x.id,
        label: x.name != null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded;
    } else {
      const optionsNeeded = fullListOfGroups.map((x: any) => ({
        value: x.id,
        label: x.name != null ? x.name : `ID: ${x.id}`,
      }));

      return optionsNeeded as any;
    }
  },
);

const initialState: IZoomMeetings = {
  fullListOfMeetings: [],
  smListOfMeetings: [],
  specificZoomMeeting: {},
  groupsThatAreNotInTheMeeting: [],
};

export const zoomMeetingsSlice = createSlice({
  name: "zoomMeetings",
  initialState,
  reducers: {
    clearAllZoomMeetings: (state: IZoomMeetings) => {
      state.fullListOfMeetings = [];
      state.smListOfMeetings = [];
    },
    updateFullListOfMeetings: (state: IZoomMeetings, { payload }) => {
      if (state.fullListOfMeetings?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfMeetings[
          state.fullListOfMeetings?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
    updateSmListOfMeetings: (state: IZoomMeetings, { payload }) => {
      if (state.smListOfMeetings?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.smListOfMeetings[
          state.smListOfMeetings?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmListOfZoomMeetings.fulfilled, (state: IZoomMeetings, { payload }: any) => {
      state.smListOfMeetings = payload;
    });
    builder.addCase(
      getFullListOfZoomMeetings.fulfilled,
      (state: IZoomMeetings, { payload }: any) => {
        state.fullListOfMeetings = payload;
      },
    );
    builder.addCase(getSpecificZoomMeeting.fulfilled, (state: IZoomMeetings, { payload }: any) => {
      state.specificZoomMeeting = payload;
    });
    builder.addCase(
      getGroupsThatAreNotInTheMeeting.fulfilled,
      (state: IZoomMeetings, { payload }: any) => {
        state.groupsThatAreNotInTheMeeting = payload;
      },
    );
    builder.addCase(updateGroupsInTheMeeting.fulfilled, (state: IZoomMeetings, action) => {
      state.specificZoomMeeting = action.payload;
      zoomMeetingsSlice.caseReducers.updateFullListOfMeetings(state, action);
      zoomMeetingsSlice.caseReducers.updateSmListOfMeetings(state, action);
    });
    builder.addCase(updateSpecificZoomMeeting.fulfilled, (state: IZoomMeetings, action) => {
      state.specificZoomMeeting = action.payload;
      zoomMeetingsSlice.caseReducers.updateFullListOfMeetings(state, action);
      zoomMeetingsSlice.caseReducers.updateSmListOfMeetings(state, action);
    });
  },
});

export const zoomMeetingsReducer = zoomMeetingsSlice.reducer;

export const getCurrentFullListOfZoomMeetings = (state: RootState) =>
  state.zoomMeetings.fullListOfMeetings;
export const getCurrentSmListOfZoomMeetings = (state: RootState) =>
  state.zoomMeetings.smListOfMeetings;
export const getCurrentSpecificZoomMeeting = (state: RootState) =>
  state.zoomMeetings.specificZoomMeeting;
export const getCurrentGroupsThatAreNotInTheMeeting = (state: RootState) =>
  state.zoomMeetings.groupsThatAreNotInTheMeeting;

export const { clearAllZoomMeetings } = zoomMeetingsSlice.actions;
