import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonIcon, ButtonText } from "ui";
import logo from "../../assets/logo.png";
import { getCurrentInfoAboutMyUser, getMyUser } from "../../features/my-user/state/myUserSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import { getSmListOfZoomMeetings } from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import {
  CheckCircleIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  BoltIcon,
  QuestionMarkCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";
import { useForm } from "react-hook-form";
import {
  createNewActiveProfilings,
  getMyCurrentActiveProfilings,
  getCurrentQuestionToAsk,
  getCurrentTrackToDo,
  getCurrentType,
  whatsNextForCoaching,
} from "../../features/profilings/state/profilingsSlice";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { createMyNewPool } from "../../features/pools/state/poolsSlice";

export const PROFILINGS_COMPONENT_ID = "profilingsComponent";
export const POOL_COMPONENT_ID = "poolComponent";

const CoachMe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const { activeProfilings, question, type, track } = useSelector((state: RootState) => {
    return {
      activeProfilings: getMyCurrentActiveProfilings(state),
      question: getCurrentQuestionToAsk(state),
      track: getCurrentTrackToDo(state),
      type: getCurrentType(state),
    };
  });

  useEffect(() => {
    dispatch(
      whatsNextForCoaching({
        componentId: PROFILINGS_COMPONENT_ID,
      }),
    ).then((res: any) => {
      console.log(res.payload);
    });
  }, [activeProfilings, dispatch]);

  useEffect(() => {
    if(type === "track")
    {
      dispatch(
        createMyNewPool({
          idOfTrack: track.id,
          componentId: POOL_COMPONENT_ID,
        }),
      ).then((res: any) => {
        console.log(res.payload);

        navigate(`/tracks/${res.payload.id}`);

      });
    }
  }, [track, type, dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log("data", data);
    dispatch(createNewActiveProfilings(data))
  };

  return (
    <div
      className="h-screen bg-cover"
      style={{
        backgroundImage: `url('${bgImage}')`,
        boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
      }}
    >
      <div className="flex w-full h-full py-10 px-6 items-center justify-center">
        <div className="w-full h-auto max-w-3xl bg-white rounded-xl shadow-lg px-6 py-6">
          <div className="flex-col lg:flex-row flex h-full min-h-[300px] my-3">
            <div className="flex flex-col h-full w-full justify-center lg:pr-3 pr-0 my-4">
              <div className="flex flex-col w-full items-center h-full">
                <IsLoading componentId={PROFILINGS_COMPONENT_ID} showSuccess={false}>
                  {type === "question" ?
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center text-3xl mb-10">
                      <QuestionMarkCircleIcon className="h-10 w-10 mr-2 text-gray-600" />
                      <label className="font-bold">{question?.name}</label>
                    </div>
                    <div className="space-y-4">
                    {question?.answers?.map((answer: any, index: number) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="radio"
                          id={`option${index}`}
                          value={answer.id}
                          {...register("answer")}
                          className="mr-2 h-6 w-6 text-lg"
                        />
                        <label htmlFor={`option${index}`} className="text-xl">
                          {answer.name}
                        </label>
                      </div>
                    ))}
                    </div>
                    <button className="btn-primary-fill text-black shadow w-auto px-6 py-2 font-semibold text-xl rounded-lg mt-10">
                      <div className="flex justify-center items-center w-auto">
                        <BoltIcon className="text-center w-6 mr-2" />
                        Submit
                      </div>
                    </button>
                  </form>
                  : <></>}
                  {type === "track" ?
                    <>
                      <div className="flex items-center text-3xl mb-10">
                        <QuestionMarkCircleIcon className="h-10 w-10 mr-2 text-gray-600" />
                        <label className="font-bold">{t("pages.coachMe.foundTrack")}</label>
                      </div>
                      <IsLoading componentId={POOL_COMPONENT_ID} showSuccess={true} />
                    </>
                  : <></>}
                </IsLoading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachMe;
