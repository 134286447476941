import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputIcon } from "ui";
import { useForm, useFormState } from "react-hook-form";
import { AppDispatch, RootState } from "../../../common/state/store";
import { useDispatch, useSelector } from "react-redux";
import { IRegisterFormData, register } from "../state/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { jwtSelector } from "../../../common/state/selectors/authSelector";
import React from "react";
import { errorSelector } from "../../../common/state/slice/appStateSlice";
import { IsLoading } from "../../../common/localComponents/AppState/IsLoading";
import { Error } from "../../../common/localComponents/AppState/Error";
import logo from "../../../assets/logo.png";
import bgImage from "../../../assets/bgMyCtoFriend.jpeg";
import { ModalStandard } from "../../../common/localComponents/Modal";
import {
  closeStandardModal,
  openStandardModal,
} from "../../../common/state/slice/modal/modalSlice";
export const REGISTRATION_COMPONENT_ID = "registrationComponent";
import { unwrapResult } from "@reduxjs/toolkit";

interface IRegistrationForm {
  labels: {
    title: string;
    subtitle: string;
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    lostpwd: string;
    register: string;
    haveAccount: string;
    termsAndConditions: string;
    login: string;
  };
  error: string;
  onSubmit: (data: any) => void;
}

export const RegistrationFormContainer = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { error, jwt } = useSelector((state: RootState) => {
    return {
      error: errorSelector(state, REGISTRATION_COMPONENT_ID),
      jwt: jwtSelector(state),
    };
  });

  const labels = {
    title: t("pages.registration.title"),
    subtitle: t("pages.registration.subtitle"),
    firstname: t("pages.registration.firstname"),
    lastname: t("pages.registration.lastname"),
    email: t("pages.registration.email"),
    password: t("pages.registration.password"),
    lostpwd: t("pages.registration.lostpwd"),
    register: t("pages.registration.register"),
    haveAccount: t("pages.registration.haveAccount"),
    termsAndConditions: t("pages.registration.termsAndConditions"),
    login: t("pages.registration.login"),
  };

  const onSubmit = (data: IRegisterFormData) => {
    console.log("submit");
    dispatch(register({ componentId: REGISTRATION_COMPONENT_ID, ...data }))
      .then(unwrapResult)
      .then(() => {
        dispatch(openStandardModal("confirmYourEmail"));
      })
      .catch((error) => {
        // Handle the error if needed
        console.error("Registration failed:", error);
      });
  };

  React.useEffect(() => {
    if (jwt && jwt !== "") {
      navigate("/");
    }
  }, [jwt]);

  return <RegistrationForm labels={labels} onSubmit={onSubmit} error={error} />;
};

const RegistrationForm = ({ labels, onSubmit }: IRegistrationForm) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { register, handleSubmit, control } = useForm<IRegisterFormData>();
  const { errors } = useFormState({ control });
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <ModalStandard id="confirmYourEmail">
        <div className="flex flex-col my-2">
          <h2 className="text-lg text-black mb-1 font-bold">
            {t("pages.registration.confirmYourEmailTitle")}
          </h2>
          <p className="text-black font-normal text-md border-0 mr-1">
            {t("pages.registration.confirmYourEmailSubtitle")}
          </p>
        </div>
        <div className="grid pt-3 justify-items-end">
          <button
            className="btn-secondary-fill text-black"
            onClick={() => {
              dispatch(closeStandardModal("confirmYourEmail"));
              window.location.href = "/login";
            }}
          >
            {t("general.okay")}
          </button>
        </div>
      </ModalStandard>
      <div
        className="flex h-screen bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
          <form className="w-full sm:w-3/4" onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white rounded-xl shadow-xl border border-gray-200 py-3 px-4">
              <div className="my-2">
                <div className="w-64 m-auto mb-3">
                  <img src={logo} alt="myctofriend-logo" />
                </div>
                <h2 className="text-md text-black mb-1 text-center font-normal">
                  {labels.subtitle}
                </h2>
                <div className="flex justify-center">
                  <div className="flex items-center">
                    <p className="text-black font-normal text-xs border-0 mr-1">
                      {labels.haveAccount}
                    </p>
                    <button className="text-blue hower:text-blue-darker font-medium text-xs border-0">
                      <Link to="/login">{labels.login}</Link>
                    </button>
                  </div>
                </div>
              </div>
              <Error componentId={REGISTRATION_COMPONENT_ID} />
              <IsLoading componentId={REGISTRATION_COMPONENT_ID} showSuccess={false} />

              <Input
                registerFct={() => register("firstname", { required: true })}
                type="text"
                label={labels.firstname}
                placeholder="Your first name"
                className={`${errors.email ? "border-red-500" : ""}`}
              />
              <Input
                registerFct={() => register("lastname", { required: true })}
                type="text"
                label={labels.lastname}
                placeholder="Your last name"
                className={`${errors.email ? "border-red-500" : ""}`}
              />
              <Input
                registerFct={() => register("email", { required: true, pattern: /^\S+@\S+$/i })}
                type="email"
                label={labels.email}
                placeholder="example@myctofriend.co"
                className={`${errors.email ? "border-red-500" : ""}`}
              />

              <InputIcon
                registerFct={() => register("password", { required: true })}
                type={passwordVisible ? "text" : "password"}
                position="right"
                label={labels.password}
                placeholder={labels.password}
                handleClick={() => setPasswordVisible(!passwordVisible)}
                icon={passwordVisible === true ? "EyeIcon" : "EyeSlashIcon"}
                className={`${errors.password ? "border-red-500" : ""}`}
              />
              <div className="flex justify-between mt-2">
                <div>
                  <input
                    type="checkbox"
                    className={`appearance-none rounded-sm mr-2 cursor-pointer checked:bg-orange ${
                      errors.termsAndConditions ? "ring-1 ring-red-500" : "ring-0"
                    }`}
                    {...register("termsAndConditions", { required: true })}
                  />
                  <label className="items-center text-xs text-gray-600 select-none">
                    {labels.termsAndConditions}
                  </label>
                </div>
              </div>
              <div className="grid pt-2 justify-items-end">
                <button className="btn-secondary-fill text-black" type="submit">
                  {labels.register}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
