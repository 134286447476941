import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import {
  getCurrentFullListOfZoomMeetings,
  getFullListOfZoomMeetings,
} from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { meetingsColumns } from "./meetings-columns";
import { ButtonIcon, DataGridComponent } from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const MEETINGS_COMPONENT_ID = "profileComponent";

const ZoomMeetings = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);

  const { fullListOfMeetings } = useSelector((state: RootState) => {
    return {
      fullListOfMeetings: getCurrentFullListOfZoomMeetings(state),
    };
  });

  useEffect(() => {
    if (!fullListOfMeetings || fullListOfMeetings.length === 0) {
      dispatch(getFullListOfZoomMeetings({ componentId: MEETINGS_COMPONENT_ID }));
    }
  }, []);

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-12 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.zoomMeetings.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfZoomMeetings({ componentId: MEETINGS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
          <IsLoading componentId={MEETINGS_COMPONENT_ID} showSuccess={false}>
            {fullListOfMeetings && (
              <DataGridComponent
                rows={fullListOfMeetings as GridRowsProp}
                columns={meetingsColumns as GridColDef[]}
              />
            )}
          </IsLoading>
        </div>
      </div>
    </>
  );
};

export default ZoomMeetings;
