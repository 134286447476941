import bgImage from "../../assets/bgMyCtoFriend.jpeg";
import { useTranslation } from "react-i18next";

const EmailConfirmed = () => {
  const { t } = useTranslation();
  return (
    <div
      className="flex h-screen bg-cover"
      style={{
        backgroundImage: `url('${bgImage}')`,
        boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
      }}
    >
      <div className="flex flex-1 max-w-xs md:max-w-lg mx-auto items-center justify-center ">
        <div className="w-full sm:w-3/4">
          <div className="bg-white rounded-xl shadow-xl border border-gray-200 py-3 px-4">
            <div className="flex flex-col my-2">
              <h2 className="text-lg text-black mb-1 font-bold">
                {t("pages.emailConfirmed.title")}
              </h2>
              <p className="text-black font-normal text-md border-0 mr-1">
                {t("pages.emailConfirmed.subtitle")}
              </p>
            </div>
            <div className="grid pt-3 justify-items-end">
              <button
                className="btn-secondary-fill text-black"
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                {t("general.okay")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmed;
