import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../state/hooks";
import { RootState } from "../../state/store";
import { closeStandardModal } from "../../state/slice/modal/modalSlice";
import { XMarkIcon } from "@heroicons/react/24/outline";

type ModalStandardProps = {
  children?: React.ReactNode;
  fullScreen?: boolean;
  id: string;
};

const ModalStandard = ({ id, children, fullScreen }: ModalStandardProps) => {
  const dispatch = useAppDispatch();
  const modalState = useSelector((state: RootState) => state.modals.showStandardModal);

  return (
    <>
      {id === modalState.id && (
        <Transition appear show={modalState.show} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10 overflow-y-auto"
            onClose={() => dispatch(closeStandardModal({ id }))}
          >
            {!fullScreen && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={"fixed inset-0 bg-black bg-opacity-25"} />
              </Transition.Child>
            )}
            <div className={`fixed ${fullScreen ? "top-0 left-28 bottom-0 right-0" : "inset-0"}`}>
              <div
                className={
                  fullScreen ? "w-full" : "flex items-center justify-center p-4 text-center h-full"
                }
              >
                <div className={!fullScreen ? "mt-auto mb-auto" : ""}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel
                      className={`h-full transform no-scrollbar  ${
                        fullScreen
                          ? "w-full max-w-full absolute top-0 right-0"
                          : "w-halfscreen max-w-3xl rounded-2xl pt-auto pb-auto"
                      } overflow-y-auto bg-slate-100 ${
                        !fullScreen && "p-6"
                      } text-left align-middle shadow-xl transition-all`}
                    >
                      {children}

                      <button
                        className="z-50 absolute top-2 right-4"
                        type="button"
                        onClick={() => {
                          dispatch(closeStandardModal({ id }));
                        }}
                      >
                        <XMarkIcon className="w-6 h-6" />
                      </button>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};

export default ModalStandard;
