import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import { Dashboard } from "../../pages/Dashboard";
import { Login, ForgottenPassword, Registration, EmailConfirmed } from "../../pages/Auth";

import ResetPassword from "../../pages/Auth/reset-password";

import Layout from "./Layout";
import {
  getCurrentJwt,
  getCurrentUser,
  logoutAuth,
  setAuth,
} from "../../features/auth/state/authSlice";
import { getAuthFromLocalStorage } from "../util/localStorage";
import { isTokenExpired } from "../util/auth";
import { ZoomMeetings, ZoomMeetingsWrapper } from "../../pages/ZoomMeetings";
import { setAuthToken } from "../util/axios";
import SpecificZoomMeeting from "../../pages/ZoomMeetings/specific-zoommeeting";
import { Pools, PoolsWrapper } from "../../pages/Tracks";
import SpecificPool from "../../pages/Tracks/specific-pools";
import MyProfile from "../../pages/Profile/aboutMe";
import MyProject from "../../pages/Profile/myProject";
import MyGroups from "../../pages/Profile/myGroups";
import MyProfileWrapper from "../../pages/Profile/myProfile-wrapper";
import CoachMe from "../../pages/Tracks/coach-me";

const Router = () => {
  const { jwt, user } = useSelector((state: RootState) => {
    return {
      jwt: getCurrentJwt(state.auth),
      user: getCurrentUser(state.auth),
    };
  });

  const [IsAuthenticated, SetIsAuthenticated] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("jwt", jwt);
    console.log("user", user);

    if (IsAuthenticated == false) {
      if (jwt === "" && !user) {
        const authFromLocalStorage = getAuthFromLocalStorage();
        if (
          authFromLocalStorage.jwt &&
          authFromLocalStorage.user &&
          authFromLocalStorage.jwt !== null &&
          !isTokenExpired(authFromLocalStorage.jwt)
        ) {
          dispatch(setAuth({ jwt: authFromLocalStorage.jwt, user: authFromLocalStorage.user }));
          setAuthToken(authFromLocalStorage.jwt);
          SetIsAuthenticated(true);
        } else {
          dispatch(logoutAuth());
        }
      }
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="tracks" element={<PoolsWrapper />}>
            <Route path="" element={<Pools />} />
            <Route path=":id" element={<SpecificPool />} />
          </Route>
          <Route path="coachme" element={<PoolsWrapper />}>
            <Route path="" element={<CoachMe />} />
          </Route>
          <Route path="meetings" element={<ZoomMeetingsWrapper />}>
            <Route path="" element={<ZoomMeetings />} />
            <Route path=":id" element={<SpecificZoomMeeting />} />
          </Route>
          <Route path="profile" element={<MyProfileWrapper />}>
            <Route path="" element={<MyProfile />} />
            <Route path="myproject" element={<MyProject />} />
            <Route path="mygroups" element={<MyGroups />} />
          </Route>

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Theres nothing here!</p>
              </main>
            }
          />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgottenpassword" element={<ForgottenPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  );
};

export default Router;
