export const baseUrl = process.env.REACT_APP_API_ENDPOINT;
/* We removed the ternary to resolve deployment issues.
You now need to pass the api endpoint manually as an environment variable.

Feel free to copy/paste the line below in your terminal :

REACT_APP_API_ENDPOINT="https://api-dev.myctofriend.co" npm run start

REACT_APP_API_ENDPOINT="https://api.myctofriend.co" npm run start
*/
const apiUrls = {
  base: baseUrl,
  auth: {
    signIn: `${baseUrl}/api/auth/local`,
    register: `${baseUrl}/api/auth/local/register`,
    sendEmailConfirmation: `${baseUrl}/api/auth/send-email-confirmation`,
    forgetPassword: `${baseUrl}/api/auth/forgot-password`,
    resetPassword: `${baseUrl}/api/auth/reset-password`,
  },
  zoomMeetings: {
    getMy: `${baseUrl}/api/meetings/findMy`,
    updateMy: `${baseUrl}/api/meetings/updateMy`,
  },
  groups: {
    get: `${baseUrl}/api/groups`,
  },
  config: {
    findOneWithSpecificName: `${baseUrl}/api/config/findOneWithSpecificName`,
  },
  myUser: {
    get: `${baseUrl}/api/users/me`,
    updateMe: `${baseUrl}/api/users-permissions/updateMe`,
  },
  projects: {
    getMy: `${baseUrl}/api/projects/findMy`,
    createMy: `${baseUrl}/api/projects/createMyFirstProject`,
    updateMy: `${baseUrl}/api/projects/updateMyFirstProject`,
  },
  pools: {
    get: `${baseUrl}/api/pools`,
    getMy: `${baseUrl}/api/pools/findMy`,
    createMy: `${baseUrl}/api/pools/createMy`,
    updateMy: `${baseUrl}/api/pools/updateMy`,
  },
  profilings: {
    get: `${baseUrl}/api/profilings`,
    getMy: `${baseUrl}/api/profilings/findMy`,
    getMyActive: `${baseUrl}/api/profilings/findMyActive`,
    createNewActive: `${baseUrl}/api/profilings/createNewActive`,
    whatsNextForCoaching: `${baseUrl}/api/profilings/whatsNextForCoaching`,
    updateMy: `${baseUrl}/api/profilings/updateMy`,
  },
};

export default apiUrls;
